import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-no-items-indicator",
  templateUrl: "./no-items-indicator.component.html",
  styleUrls: ["./no-items-indicator.component.scss"],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class NoItemsIndicatorComponent {
  @Input()
  icon?: string;

  @Input()
  text?: string;

  @Input()
  showAsCard = true;
}
